import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../../environments/environment';
import { EnvironmentNames } from '../../_core/contants/environments';

@Pipe({
  name: 'fundNameFormat',
})
export class FundNameFormatPipe implements PipeTransform {
  regexFundNumber = /\(#[^)]*\)$/;
  regexParentheses = /\([^)]*\)$/;

  transform(value: string): string {
    const isSinngeber = environment.environmentName === EnvironmentNames.SINNGEBER;

    if (isSinngeber) return value;

    const { cleanedString, removedPortion } = this.removeParentheses(value);

    let words = cleanedString.split(' ');

    // Check if the first word is "The"
    if (words[0].toLowerCase() === 'the') {
      words.shift(); // Remove the first word
    }

    // Check if the last word is "Fund"
    if (words[words.length - 1].toLowerCase() === 'fund') {
      words.pop(); // Remove the last word
    }

    let returnArr = ['The', ...words, 'Fund'];

    // Adjust array if the last word matches regex and second to last word is "fund"
    if (words[words.length - 1].match(this.regexParentheses) && words[words.length - 2].toLowerCase() === 'fund') {
      returnArr.pop(); // Remove the last word
    }

    if (removedPortion) returnArr = [...returnArr, removedPortion];

    return returnArr.join(' ');
  }

  removeParentheses(inputString: string): { cleanedString: string; removedPortion: string | null } {
    // Use regex to find and remove the portion enclosed in parentheses at the end of the string
    const match = inputString.match(this.regexFundNumber);
    const cleanedString = inputString.replace(this.regexFundNumber, '').trim();
    return { cleanedString, removedPortion: match ? match[0] : null };
  }
}
