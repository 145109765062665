<div class="modals">
  <div class="modal-content">
    <div class="d-flex justify-content-between align-items-center pr-4">
      <h3 class="my-4 mx-4 main-color">
        {{ (params.isGrant ? 'HEADER_GRANT_EDIT_RECURRING_GRANTS' : 'HEADER_DISTRIBUTE_EDIT_RECURRING_DISTRIBUTIONS') | translate }}
      </h3>
      <div class="px-2 py-1 pointer" (click)="closeModal()"><i class="fas fa-times"></i></div>
    </div>

    <app-loading-spinner *ngIf="modalLoading"></app-loading-spinner>

    <div *ngIf="!modalLoading" class="modal-body mx-4">
      <ng-container *ngIf="!showSummary">
        <!-- Recurring Options -->
        <app-recurring-form
          [hideNoEndDate]="true"
          [control]="editTransactionForm.controls.recurrence"
          [dateFormat]="dateFormat$ | async"
          [disableStartDate]="startDateDisabled"
          [checkGlobalConfig]="true"
        ></app-recurring-form>
        <!-- Amount -->
        <div class="input-group d-flex custom">
          <div class="amount">
            <app-text-input [control]="amount" [skipValidation]="true" [type]="inputTypes.CURRENCY"></app-text-input>
          </div>
          <!-- Currency -->
          <div class="currency">
            <app-text-input [control]="currency" [skipValidation]="true"></app-text-input>
          </div>
        </div>
        <app-validation-message [control]="amount"></app-validation-message>
        <div class="break-word small required" *ngIf="amount.helperData.showError">
          {{ 'TOASTER_MINIMUM_AMOUNT_NOT_REACHED' | translate : { amount: amount.helperData.minValue } }}.
        </div>
        <div class="break-word small text-grey" *ngIf="amount.helperData.isVerifying">
          {{ 'TOASTER_CHECKING_AMOUNT' | translate }}
        </div>
        <p class="small">
          {{ 'GRANT_AVAILABLE_AMOUNT' | translate }}:
          <span class="font-weight-bold">{{ amount.helperData.availableValue | number : '1.2-2' | numberFormat }}</span>
          {{ params.transaction.currencySymbol }}
        </p>
        <!-- Range error/warning -->
        <div class="text-danger" role="alert" *ngIf="editTransactionForm.errors?.rangeError">
          {{ 'GRANT_RECURRING_INSUFFICIENT_ERROR' | translate }}
        </div>
        <div class="text-warning" role="alert" *ngIf="grantWarning && !amount.errors?.maxAmountAllowed">
          {{ 'GRANT_RECURRING_INSUFFICIENT_WARNING' | translate }}
        </div>
      </ng-container>

      <!-- Summary -->
      <ng-container *ngIf="showSummary">
        <h3 class="mb-4">
          <b class="text-black">{{ 'HEADER_SHOW_CHANGES_SUMMARY' | translate }}</b>
        </h3>
        <div class="container">
          <div class="row py-2 border-top">
            <div class="col">{{ 'SUMMARY_RECURRENCE_PATTERN' | translate }}</div>
            <div class="col">
              {{
                (updatedValues.recurrencePattern === initialValues.recurrencePattern
                  ? 'SUMMARY_NO_CHANGE'
                  : 'DONATE_RECURRING_CONTRIBUTION_LABELS_PATTERN_' + updatedValues.recurrencePattern
                ) | translate
              }}
            </div>
          </div>
          <div class="row py-2 border-top">
            <div class="col">{{ 'DONATE_MANAGE_RECURRING_CONTRIBUTION_START_DATE' | translate }}</div>
            <div class="col">
              {{
                updatedValues.startDate === initialValues.startDate
                  ? ('SUMMARY_NO_CHANGE' | translate)
                  : (updatedValues.startDate | date : (dateFormat$ | async))
              }}
            </div>
          </div>
          <div class="row py-2 border-top">
            <div class="col">{{ 'DONATE_MANAGE_RECURRING_CONTRIBUTION_END_DATE' | translate }}</div>
            <div class="col">
              {{
                updatedValues.endDate === initialValues.endDate
                  ? ('SUMMARY_NO_CHANGE' | translate)
                  : (updatedValues.endDate | date : (dateFormat$ | async))
              }}
            </div>
          </div>
          <div class="row py-2 border-top">
            <div class="col">{{ 'GENERAL_AMOUNT' | translate }}</div>
            <div class="col">
              {{
                updatedValues.amount === initialValues.amount
                  ? ('SUMMARY_NO_CHANGE' | translate)
                  : (updatedValues.amount | number : '1.2-2' | numberFormat)
              }}
              <span *ngIf="updatedValues.amount !== initialValues.amount">{{ initialValues.currencySymbol }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer mt-3 justify-content-center">
      <button type="button" class="lg-button-secondary" (click)="onCancel()">
        {{ (showSummary ? 'GENERAL_BACK' : 'GENERAL_CANCEL') | translate }}
      </button>
      <button
        type="button"
        class="lg-button"
        [disabled]="
          editTransactionForm.invalid ||
          amount.helperData.showError ||
          amount.helperData.isVerifying ||
          editTransactionForm.errors?.rangeError ||
          formTouched === false ||
          modalLoading
        "
        (click)="onNextOrSubmit()"
      >
        {{ showSummary ? ('GENERAL_OK' | translate | uppercase) : ('GENERAL_NEXT' | translate) }}
      </button>
    </div>
  </div>
</div>
